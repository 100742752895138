/* eslint-disable no-magic-numbers */
import React, { ReactNode } from 'react';
import { RoundIconButton } from '@hz-design-system/web-ui';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { ListingImage, ListingImageNone, ListingOpvalSticker } from '@/client/components/Listings/atoms';
import { Images } from '@/client/types/Images';
import mediaQueries from '@/client/utils/browser/mediaQueries';
import { formatImageUrl } from '@ecg-marktplaats/media-util-js-lib';
import { MAIN_IMAGE_SIZE_LIST_DESKTOP } from '@/client/constants/listings';
import CATEGORIES_L1 from '@/constants/categories';

const classNames = {
  imageContainer: getSuitCSSClasses({ namespace: 'hz', name: 'Listing-image-container' }),
  imageContainerDetailsOpen: getSuitCSSClasses({
    namespace: 'hz',
    name: 'Listing-image-container',
    modifiers: ['details-open'],
  }),
  subImages: getSuitCSSClasses({ namespace: 'hz', name: 'Listing-sub-images' }),
  subImagesDetailsOpen: getSuitCSSClasses({
    namespace: 'hz',
    name: 'Listing-sub-images',
    modifiers: ['details-open'],
  }),
};

interface ImageItemProps {
  kind?: string;
  image: Images;
  loadingType?: 'eager' | 'lazy';
  decodingType?: 'sync' | 'async' | 'auto';
  children?: ReactNode;
}

const ImageItem: React.FC<ImageItemProps> = ({ kind, image, loadingType, decodingType, children }) => (
  <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-image-item', modifiers: [kind] })}>
    <ListingImage image={image} loadingType={loadingType} decodingType={decodingType} />
    {children}
  </div>
);

const NoImageItem: React.FC<{ kind: string }> = ({ kind }) => {
  const redesignModifiers = [kind];

  return (
    <div
      className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-image-none-item', modifiers: redesignModifiers })}
    >
      <ListingImageNone />
    </div>
  );
};

interface MainImageItemProps {
  image: Images;
  loadingType?: 'eager' | 'lazy';
  decodingType?: 'sync' | 'async' | 'auto';
  children?: ReactNode;
}

const MainImageItem: React.FC<MainImageItemProps> = ({ image, loadingType, decodingType, children }) => {
  if (mediaQueries.isDesktop() && image?.url) {
    image.imageSource = formatImageUrl(image.url, MAIN_IMAGE_SIZE_LIST_DESKTOP, image.sizes);
  }

  return (
    <ImageItem image={image} kind="main" loadingType={loadingType} decodingType={decodingType}>
      {children}
    </ImageItem>
  );
};

interface ListingFigureProps {
  images: Images[];
  opvalStickerText?: string;
  loadingType?: 'eager' | 'lazy';
  decodingType?: 'sync' | 'async' | 'auto';
  isFavorite?: boolean;
  onFavorite?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  l1Category?: number;
  isDetailsOpen?: boolean;
}

const ListingFigure: React.FC<ListingFigureProps> = ({
  images,
  opvalStickerText,
  loadingType,
  decodingType,
  isFavorite,
  onFavorite,
  l1Category,
  isDetailsOpen,
}) => {
  return (
    <figure className={isDetailsOpen ? classNames.imageContainerDetailsOpen : classNames.imageContainer}>
      {images.length === 0 && <NoImageItem kind="main" />}
      {images.length > 0 && <MainImageItem image={images[0]} loadingType={loadingType} decodingType={decodingType} />}
      {images.length > 2 && (
        <div className={isDetailsOpen ? classNames.subImagesDetailsOpen : classNames.subImages}>
          <ImageItem image={images[1]} loadingType={loadingType} decodingType={decodingType} />
          <ImageItem image={images[2]} loadingType={loadingType} decodingType={decodingType} />
        </div>
      )}
      {opvalStickerText && <ListingOpvalSticker>{opvalStickerText}</ListingOpvalSticker>}
      {l1Category === CATEGORIES_L1.CARS && (
        <RoundIconButton.Secondary
          name={isFavorite ? 'heart_filled' : 'heart'}
          className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-favorite-button' })}
          onClick={onFavorite}
        />
      )}
    </figure>
  );
};

export default ListingFigure;
