import React, { useContext, useEffect } from 'react';
import { loadAdsenseSearch, adsenseLoadedCallback } from '@ecg-marktplaats/ecg-js-adsense';

import ADSENSE_EVENTS from '@/constants/adsense';
import { logger } from '@lrp/logger';

import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import getIsMobile from '@/client/utils/browser/isMobile';

type TAdsenseForSearchProps = {
  query?: string;
  searchCategoryName?: string;
  personalizedAds?: boolean;
  pageType: 'LRP' | 'ZRP';
  currentPage: number;
  bottomBlockContainerId: string;
  topBlockContainerId?: string;
  channel: string;
};

const AdsenseForSearch = ({
  query = '',
  searchCategoryName = '',
  pageType,
  currentPage,
  bottomBlockContainerId,
  topBlockContainerId,
  channel,
  personalizedAds = false,
}: TAdsenseForSearchProps) => {
  const { isProduction, tenantContext, properties } = useContext(EnvironmentContext);

  const pubId = (query ? properties?.adsenseSearchClientId : properties?.adsenseBrowseClientId) as string;

  useEffect(() => {
    if (typeof window === 'undefined') return;

    let styleId = '';

    if (tenantContext?.tenant === 'mp') {
      styleId = getIsMobile() ? '1407626141' : '5957710024';
    } else {
      styleId = getIsMobile() ? '5964898986' : '4651816683';
    }

    const adLoadedCallback = (_containerName: string, adsLoaded: boolean) => {
      adsenseLoadedCallback(adsLoaded, ADSENSE_EVENTS.REQUESTED, ADSENSE_EVENTS.LOADED);
    };

    const adsenseOptions = {
      pageOptions: {
        query: query || searchCategoryName,
        adtest: isProduction ? ('off' as const) : ('on' as const),
        adPage: currentPage,
        numRepeated: '0',
        personalizedAds,
        pubId,
        channel,
        hl: tenantContext?.locale.split('-')[0],
        styleId,
      },
      pageType,
      topBlockContainerId,
      bottomBlockContainerId,
      adLoadedCallback,
    };

    try {
      window.mpAdsenseOptions = window.mpAdsenseOptions || [];
      window.mpAdsenseOptions.push(adsenseOptions);

      loadAdsenseSearch(adsenseOptions);
    } catch (e) {
      logger.warn('loadAdsense', e);
    }
  }, [
    query,
    searchCategoryName,
    pageType,
    currentPage,
    topBlockContainerId,
    bottomBlockContainerId,
    channel,
    isProduction,
    pubId,
    personalizedAds,
    tenantContext?.tenant,
    tenantContext?.locale,
  ]);

  return <div suppressHydrationWarning id={bottomBlockContainerId} data-testid={bottomBlockContainerId} />;
};

export default AdsenseForSearch;
