/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import Head from 'next/head';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import safeJsonSerialize from '@/utils/safeJsonSerialize';
import excludeTextAttributesFromNormalizing from '@/utils/seo/excludeTextAttributesFromNormalizing';

interface Breadcrumbs {
  '@context': string;
  '@type': string;
  itemListElement: any[];
}

interface Product {
  '@context': string;
  '@type': string;
}

interface AltLocaleWithUrl {
  locale: string;
  url: string;
}

interface SeoMetaTagsProps {
  breadcrumbs: Breadcrumbs;
  product?: Product;
  canonicalUrl: string;
  title: string;
  description: string;
  content: string;
  generationTimeMillis: number;
  altLocaleWithUrl?: AltLocaleWithUrl;
  hrefLangUrl?: string;
  localeAlt?: AltLocaleWithUrl;
}

const SeoMetaTags: React.FC<SeoMetaTagsProps> = ({
  breadcrumbs,
  product,
  canonicalUrl,
  title,
  description,
  content,
  generationTimeMillis,
  altLocaleWithUrl,
  hrefLangUrl,
  localeAlt,
}) => {
  const {
    tenantContext: { locale },
  } = useContext(EnvironmentContext);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta key="robots" name="robots" content={content} />

      {altLocaleWithUrl && (
        <>
          <link rel="alternate" hrefLang={locale.split('-')[0]} href={hrefLangUrl} />
          <link rel="alternate" hrefLang={localeAlt?.locale.split('-')[0]} href={altLocaleWithUrl.url} />
          <link rel="alternate" hrefLang="x-default" href={hrefLangUrl} />
        </>
      )}

      <meta name="generation-time-millis" content={generationTimeMillis.toString()} />

      <link key="canonical" rel="canonical" href={excludeTextAttributesFromNormalizing(canonicalUrl)} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: safeJsonSerialize(breadcrumbs) }} />
      {Boolean(product) && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: safeJsonSerialize(product) }} />
      )}
    </Head>
  );
};

export default SeoMetaTags;
