import React, { useState, useEffect, useRef } from 'react';
import AdsenseForSearch from '@/client/components/AdsenseForSearch';
import lazyLoadScript from '@/client/utils/browser/lazyLoadScript';
import { isIntersectionObserverSupported } from '@/client/utils/browser/isInViewport';
import { ADSENSE_TOP_CONTAINER_ID } from '@/constants/adsense';
import isSsr from '@/client/utils/isSsr';

interface AdsenseContainerProps {
  query?: string;
  pageType: 'LRP' | 'ZRP';
  searchCategoryName?: string;
  channel: string;
  currentPage: number;
}

const AdsenseContainer: React.FC<AdsenseContainerProps> = ({
  query = '',
  pageType,
  searchCategoryName = '',
  channel,
  currentPage,
}) => {
  const [isIntersected, setIsIntersected] = useState(false);
  const lazyAdsenseContainerRef = useRef<HTMLDivElement>(null);
  const isUserConsentPresent = useRef(false);
  const intersectionObserver = useRef<IntersectionObserver | null>(null);

  const showAdsenseWhenIntersecting = () => {
    const node = lazyAdsenseContainerRef.current;
    if (!node) return;

    const offsetTop = 1500;
    const options = {
      rootMargin: `0px 0px ${offsetTop}px 0px`,
    };

    intersectionObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        intersectionObserver.current?.unobserve(entries[0].target);
        setIsIntersected(true);
      }
    }, options);

    intersectionObserver.current.observe(node);
  };

  const gaEvent = (label: string) => {
    if (window && window.ecGa) {
      window.ecGa('trackEvent', {
        eventAction: 'LazyLoadAdsense',
        eventLabel: label,
      });
    }
  };

  const init = () => {
    if (isIntersectionObserverSupported.get()) {
      showAdsenseWhenIntersecting();
    } else {
      setIsIntersected(true);
      gaEvent('notSupported');
    }
  };

  useEffect(() => {
    if (isSsr()) return;

    init();
    isUserConsentPresent.current = !!window?._sp_lib?.getConsentState(document.cookie)?.Google;

    return () => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSsr()) return;

    if (isIntersected) {
      lazyLoadScript.load({
        src: '//www.google.com/adsense/search/ads.js',
        async: true,
      });
    }
  }, [isIntersected]);

  return (
    <div ref={lazyAdsenseContainerRef} id="adsense-container" suppressHydrationWarning>
      {isIntersected ? (
        <AdsenseForSearch
          query={query}
          searchCategoryName={searchCategoryName}
          pageType={pageType}
          currentPage={currentPage}
          bottomBlockContainerId="adsense-container-bottom-lazy"
          topBlockContainerId={ADSENSE_TOP_CONTAINER_ID}
          channel={channel}
          personalizedAds={isUserConsentPresent.current}
        />
      ) : null}
    </div>
  );
};

export default AdsenseContainer;
