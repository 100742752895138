import React from 'react';
import { number } from 'prop-types';
import { Tabs as TabsComponent, Tab } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

const Tabs = ({ sellerId }) => {
  const { t } = useI18nContext();

  const aboutUsUrl = `/smb-profile/profile/${sellerId}`;

  const handleTabClick = (e) => {
    e.preventDefault();
    window.location.href = aboutUsUrl;
  };
  return (
    <TabsComponent>
      <Tab href="#" onClick={handleTabClick} label={t('soi.sellerHeader.aboutUs')} />
      <Tab isActive label={t('soi.sellerHeader.allAds')} />
    </TabsComponent>
  );
};

Tabs.propTypes = {
  sellerId: number.isRequired,
};

export default Tabs;
