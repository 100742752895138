import React, { useContext } from 'react';
import { shape, string, bool, node, number, array, object, func } from 'prop-types';
import { Link, MaskedLink } from '@hz-design-system/web-ui';

import pushToRouter from '@/client/utils/pushToRouter';
import { generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import trackEvent from '@/client/utils/analytics/trackEvent';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import * as types from '@/client/types';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import experiments from '@/utils/labs/experiments';
import { createLinks, shouldMaskLinkFromCrawler } from '@/utils/links/linkHelpers';
import CATEGORIES_L1 from '@/constants/categories';

const onClickHandler = ({
  event,
  pageQueryObj: query,
  href,
  eventAction,
  eventLabel,
  eventMetric,
  preventScroll,
  onClickCallback,
} = {}) => {
  trackEvent({ eventAction, eventLabel, eventMetric });

  if (onClickCallback) {
    onClickCallback();
  }

  if (preventScroll) {
    if (typeof window !== 'undefined') {
      window.preventScroll = true;
    }
  }

  if (href !== '/') {
    event.preventDefault();
    pushToRouter({ query, href });
  }
};

const LinkGenerator = ({
  eventAction,
  eventLabel,
  eventMetric,
  className,
  children,
  // link options
  searchRequestObject,
  rangeAttributes,
  categories,
  query = '',
  attributes = {},
  page = 1,
  pageSize,
  isSingleSelect,
  isSelected,
  removeAllFilters = false,
  removeAllAttributes = false,
  bypassSpellingSuggestion,
  allowSavedSearch = false,
  distance,
  isSuggestedSearch = false,
  preventScroll = false,
  onClick: onClickCallback,
}) => {
  const { labsConfig } = useContext(EnvironmentContext);
  const { isFetchResultsOnceEnabled, isFetchResultsOnceAllCatEnabled } = experiments({ labsConfig });
  const withAllAttributes =
    isFetchResultsOnceAllCatEnabled ||
    (isFetchResultsOnceEnabled && searchRequestObject.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN);
  const linkOptions = {
    searchRequestObject,
    ...(query && { query }),
    newAttribute: attributes,
    isSingleSelect: !!isSingleSelect,
    isSelected: !!isSelected,
    rangeAttributes,
    categories,
    removeAllFilters,
    removeAllAttributes,
    page,
    pageSize,
    bypassSpellingSuggestion,
    allowSavedSearch,
    distance,
    isSuggestedSearch,
  };

  const linkObject = generateLinkObject(linkOptions);
  let linkObjectWithAllAttributes;
  let href;
  let pageQueryObj;

  if (withAllAttributes) {
    linkOptions.withAllAttributes = true;
    linkObjectWithAllAttributes = generateLinkObject(linkOptions);
    href = createLinks(linkObjectWithAllAttributes);
  } else {
    href = createLinks(linkObject);
  }

  const shouldMask = shouldMaskLinkFromCrawler({
    seller: linkObject.seller,
    prioritizedAttributes: linkObject.prioritizedAttributes,
    rangeAttributes: linkObject.rangeAttributes,
  });

  const LinkComponent = shouldMask ? MaskedLink : Link;

  if (withAllAttributes) {
    pageQueryObj = getClientPageQueryObject(linkObjectWithAllAttributes);
  } else {
    pageQueryObj = getClientPageQueryObject(linkObject);
  }

  // Generate link component
  const onClick = (event) =>
    onClickHandler({
      event,
      pageQueryObj,
      href,
      eventAction,
      eventLabel,
      eventMetric,
      preventScroll,
      onClickCallback,
    });

  return (
    <LinkComponent onClick={onClick} href={href} className={className}>
      {children}
    </LinkComponent>
  );
};

LinkGenerator.propTypes = {
  eventAction: string,
  eventLabel: string,
  eventMetric: shape({
    string: string,
  }),
  className: string,
  children: node,
  rangeAttributes: array,
  isSingleSelect: bool,
  isSelected: bool,
  attributes: object,
  page: number,
  categories: shape({
    l1Category: shape({ id: number, key: string }),
    l2Category: shape({ id: number, key: string }),
  }),
  distance: object,
  query: string,
  pageSize: number,
  removeAllFilters: bool,
  removeAllAttributes: bool,
  searchRequestObject: types.SearchRequest.isRequired,
  allowSavedSearch: bool,
  bypassSpellingSuggestion: bool,
  isSuggestedSearch: bool,
  preventScroll: bool,
  onClick: func,
};

export default LinkGenerator;
