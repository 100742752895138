import breakpoints from '@/client/constants/breakpoints';

const DEFAULT_IFRAME_WIDTH = 640;
const DEFAULT_IFRAME_HEIGHT = 680;

const calculateIFrameWidth = () => {
  if (typeof window !== 'undefined' && window.innerWidth < breakpoints.SMALL) {
    return window.innerWidth;
  }
  return DEFAULT_IFRAME_WIDTH;
};

const calculateIFrameHeight = () => {
  const offset = 65;
  if (typeof window !== 'undefined' && window.innerHeight < breakpoints.SMALL) {
    return window.innerHeight - offset;
  }
  return DEFAULT_IFRAME_HEIGHT;
};

const buildIFrameSrc = (sellerId) => `/experiences/user-reviews/${sellerId}`;

export { calculateIFrameWidth, calculateIFrameHeight, buildIFrameSrc };
