import React, { useEffect, useState } from 'react';

interface BannerProps {
  position: string;
  type: string;
  loading?: boolean;
}

const BannerListItem: React.FC<BannerProps> = ({ position, type, loading }) => {
  const loadingClassName = loading ? 'loading' : '';

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted) {
    return null;
  }

  return (
    <div suppressHydrationWarning className={`hz-Banner hz-Banner--${type}`}>
      <div suppressHydrationWarning id={`banner-${position}`} className={loadingClassName}></div>
    </div>
  );
};

export default BannerListItem;
