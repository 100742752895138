import { differenceInCalendarDays, parseISO } from 'date-fns';

const dateFormat = "d MMM ''yy";

const listingsDateFormatter = (dateString, i18n) => {
  const date = typeof dateString === 'string' ? parseISO(dateString) : dateString;

  const yesterday = -1;
  const dayBeforeYesterday = -2;

  const diff = differenceInCalendarDays(date, new Date());

  if (diff >= 0) {
    return i18n.t('dates.today');
  }

  if (diff === yesterday) {
    return i18n.t('dates.yesterday');
  }

  if (diff === dayBeforeYesterday) {
    return i18n.t('dates.dayBeforeYesterday');
  }

  return i18n.date(date, dateFormat).toString();
};

export default listingsDateFormatter;
