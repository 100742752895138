let $window;

try {
  if (typeof window !== 'undefined' && window.matchMedia) {
    $window = window;
  } else {
    throw new Error('environment is node.');
  }
} catch (e) {
  $window = { matchMedia: () => ({ matches: false }) };
}

const queries = {
  small: $window.matchMedia('screen and (max-width: 768px)'),
  medium: $window.matchMedia('screen and (min-width: 768px) and (max-width: 992px)'),
  large: $window.matchMedia('screen and (min-width: 992px)'),
  desktop: $window.matchMedia('screen and (min-width: 1024px)'),
  desktopLarge: $window.matchMedia('screen and (min-width: 1200px)'),
};

export default {
  isSmall: () => queries.small.matches,
  isMedium: () => queries.medium.matches,
  isLarge: () => queries.large.matches,
  isDesktop: () => queries.desktop.matches,
  isDesktopLarge: () => queries.desktopLarge.matches,
};
