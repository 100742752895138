import { seoUrlPart } from '@ecg-marktplaats/aurora-js-formatters';
import { RootCategory } from '@/constants/header';
import memoize from './memoize';

export const createCategoryKey = memoize((categoryName) => seoUrlPart(categoryName).toLowerCase());

export const createCategoriesMap = (categories) =>
  categories.reduce((categoriesMap, currentCategory) => {
    const categoryKey = createCategoryKey(currentCategory.fullName);
    const newCategory = {
      ...currentCategory,
      key: categoryKey,
      children: currentCategory.children ? createCategoriesMap(currentCategory.children) : {},
    };
    return { ...categoriesMap, [categoryKey]: newCategory };
  }, {});

export const createCategoriesFlatMap = (categories, keyToUse = 'id') => {
  const flatMap = {};
  Object.keys(categories).forEach((k) => {
    Object.assign(flatMap, { [categories[k][keyToUse]]: categories[k] });
    Object.keys(categories[k].children).forEach((ck) => {
      const child = { ...categories[k].children[ck] };
      child.parentKey = k;
      Object.assign(flatMap, { [child[keyToUse]]: child });
    });
  });
  return flatMap;
};

/**
 * Returns the id of the category we searched in. If there is an L2 we return the l2,
 * otherwise if there is an L1 we return the l1, otherwise we return 0.
 * It can handle both search request types present in this project!
 */
export const categoryIdFromRequest = (searchRequest) => {
  const { categories: { l1Category, l2Category } = {}, l1CategoryId = 0, l2CategoryId = 0 } = searchRequest;
  const l1 = l1Category ? l1Category.id : l1CategoryId;
  const l2 = l2Category ? l2Category.id : l2CategoryId;
  return l2 || l1;
};

/**
 * Returns the name of the category we searched in. If there is an L2 we return its name,
 * otherwise if there is an L1 we return its name, otherwise we return the translation for
 * 'all platform'.
 * It can handle both search request types present in this project!
 */
export const categoryNameFromRequest = (searchRequest, categoriesById, t) => {
  const id = categoryIdFromRequest(searchRequest);
  return id ? categoriesById[id].name : t('breadcrumbs.allPlatform');
};

/**
 * Remove children field and keep only those fields that are necessary
 */
const removeChildren = ({ fullName, id, key, name, parentId }) => ({
  fullName,
  id,
  key,
  name,
  ...(parentId && { parentId }),
});

/**
 * Given the categories part of a search request filter as an array, in which a category is given as an id
 * and possible a parentId (if selected category is L2), it returns an object containing the following:
 * - searchCategory is the id of the selected category
 * - searchCategoryOptions the list of categories that should be displayed in the header
 */
export const getHeaderCategoryTree = (selectedCategories = [], categoryMap, categoryIdMap) => {
  const { id: categoryId = 0, parentId: parentCatId = 0 } = selectedCategories.length ? selectedCategories[0] : {};
  const l1CategoryId = parentCatId || categoryId;
  const addParentKey = (categoryObject) => {
    const { parentId, ...rest } = categoryObject;
    return { ...rest, ...(parentId && { parentId, parentKey: categoryIdMap[parentId].key }) };
  };

  const l1WithL2Combined = () => {
    const l1 = categoryIdMap[l1CategoryId];
    return [l1].concat(Object.values(l1.children));
  };
  const l1categories = () => Object.values(categoryMap);

  const searchCategoryOptions = (l1CategoryId ? l1WithL2Combined() : l1categories())
    .filter((category) => !category.readOnly)
    .map(removeChildren)
    .map(addParentKey);

  const searchCategory = searchCategoryOptions.map((category) => category.id).includes(categoryId)
    ? categoryId
    : RootCategory;

  return {
    searchCategory,
    searchCategoryOptions,
  };
};

/**
 * provided with an array of search requests (e.g., spell corrected search) it combines the
 * categories in them and returns a map by id to all category information.
 */
export const getCategoryMapFor = (searchRequests, categoryIdMap) => {
  const categoriesById = {};
  searchRequests
    .flatMap((sr) => sr.filters.categories || [])
    .flatMap((c) => [c.id, c.parentId])
    .forEach((id) => {
      if (id) {
        categoriesById[id] = removeChildren(categoryIdMap[id]);
      }
    });
  return { categoriesById };
};
