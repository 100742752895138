import React, { useContext, useState } from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { bool } from 'prop-types';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { PrimaryButton, Icon, TextField, Block } from '@hz-design-system/web-ui';

import linkGeneratorHelper, { generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import pushToRouter from '@/client/utils/pushToRouter';
import SearchRequest from '@/client/types/SearchRequest';
import trackEvent from '@/client/utils/analytics/trackEvent';

import experiments from '@/utils/labs/experiments';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import CATEGORIES_L1 from '@/constants/categories';

const classes = {
  root: getSuitCSSClasses({
    namespace: 'hz',
    name: 'Alert',
    modifiers: ['info'],
  }),
};

const ZRPSearchHelper = ({ searchRequestObject, hasErrors }) => {
  const { t } = useI18nContext();
  const { tenantContext, labsConfig } = useContext(EnvironmentContext);
  const { isFetchResultsOnceEnabled, isFetchResultsOnceAllCatEnabled } = experiments({
    labsConfig,
    tenantContext,
  });
  const withAllAttributes =
    isFetchResultsOnceAllCatEnabled ||
    (isFetchResultsOnceEnabled && searchRequestObject.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN);
  const [newSearchQuery, setNewSearchQuery] = useState('');

  const { searchQuery } = searchRequestObject;

  const handleQueryChange = (event) => {
    setNewSearchQuery(event.target.value);
  };

  const handleSubmit = () => {
    const keyword = newSearchQuery || searchQuery;
    const linkOptions = {
      ...searchRequestObject,
      query: keyword,
      searchRequestObject,
      withAllAttributes,
    };
    const href = linkGeneratorHelper(linkOptions);
    const query = getClientPageQueryObject(generateLinkObject(linkOptions));

    trackEvent({
      eventAction: 'ZRPSearchClick',
      eventLabel: 'ZRPExperiment',
    });

    pushToRouter({ query, href });
  };

  const handleQueryKeyUp = (event) => {
    const enterKeyCode = 13;
    if (event.which === enterKeyCode || event.keyCode === enterKeyCode) {
      handleSubmit();
    }
  };

  return (
    <div className={`${classes.root} zrp-search-helper-container`}>
      <Trans
        className="zrp-search-helper-title hz-text-header3"
        i18nKey={`messages.no_results_found_search_box_title${hasErrors ? '_error' : ''}`}
        query={searchQuery}
      />
      {!hasErrors && (
        <Trans
          className="zrp-search-helper-description hz-text-meta"
          i18nKey="messages.no_results_found_search_box_description"
        />
      )}
      <Block className="zrp-search-helper-input-group">
        <Icon size="sm" name="search" />
        <TextField
          type="text"
          placeholder={searchQuery}
          value={newSearchQuery}
          onChange={handleQueryChange}
          onKeyUp={handleQueryKeyUp}
        />
        <PrimaryButton onClick={handleSubmit}>{t('messages.no_results_found_search_box_button')}</PrimaryButton>
      </Block>
    </div>
  );
};

ZRPSearchHelper.propTypes = {
  searchRequestObject: SearchRequest.isRequired,
  hasErrors: bool,
};

export default ZRPSearchHelper;
