import React, { useState } from 'react';

import { BasicModal, Spinner } from '@hz-design-system/web-ui';

import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import * as IFrameUtils from '@/client/components/SellerHeader/ReviewsDialog/utils';

interface ReviewsDialogProps {
  sellerId: number;
  onClose: () => void;
}

const ReviewsDialog: React.FC<ReviewsDialogProps> = ({ sellerId, onClose }) => {
  const { t } = useI18nContext();

  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  const handleIFrameLoad = () => setIsIFrameLoaded(true);

  return (
    <BasicModal title={t('soi.reviewsDialog.title')} onClose={onClose} size="l">
      {!isIFrameLoaded && <Spinner />}
      <iframe
        id="user-reviews-dialog-iframe"
        title="user-reviews-dialog"
        src={IFrameUtils.buildIFrameSrc(sellerId)}
        width={IFrameUtils.calculateIFrameWidth()}
        height={IFrameUtils.calculateIFrameHeight()}
        onLoad={handleIFrameLoad}
        data-testid="user-reviews-dialog-iframe"
      />
    </BasicModal>
  );
};

export default ReviewsDialog;
