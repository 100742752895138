import { getGptBannersServerData } from '@lrp/api-client';
import releaseId from '@/constants/releaseId';

type TDominantCategory = {
  dominant: boolean;
  histogramCount: number;
  id: number;
  isValuableForSeo: boolean;
  key: string;
  label: string;
  parentId: number;
  parentKey: string;
  selected: boolean;
};
type TcreateBannerDataConfigProps = {
  xsrfToken: string;
  adblockerFile: string;
  mainScriptUrl: string;
  experiments: Record<string, boolean>;
  categoryInformation: { categoryId: string; parentCategoryId: string };
  hostname: string;
  positions: string[];
  labsConfig: TLabsConfig;
  loggedInUser: TLoggedInUser & { isLoggedIn: boolean };
  fingerPrint: string;
  tenantContext: TTenantContext;
  pageKey: string;
  vurl: string;
  rsiSegs: string;
  bannerProperties: {
    adblockerFile: string;
    mainScriptUrl: string;
    gptUrl: string;
    gptBatchMetricsEndpointUrl: string;
    malvertisingProtectionId: string;
    analyticsCode: string;
  };
  extraBannerParameters: any;
  useSourcePointCMP: boolean;
  dominantCategory: TDominantCategory | undefined;
  consent?: string;
};

const createBannerDataConfig = async ({
  xsrfToken,
  adblockerFile,
  mainScriptUrl,
  experiments,
  categoryInformation: { categoryId, parentCategoryId },
  hostname,
  positions,
  labsConfig,
  loggedInUser,
  fingerPrint,
  tenantContext,
  pageKey,
  vurl,
  rsiSegs,
  bannerProperties,
  extraBannerParameters,
  useSourcePointCMP,
  dominantCategory,
  consent,
}: TcreateBannerDataConfigProps) => {
  let catIdString = '';
  const { enableTmp, skinTest, isPubmaticWrapperEnabled } = experiments;

  if (categoryId) {
    catIdString = parentCategoryId ? `${parentCategoryId}` : `${categoryId}`;
  } else {
    catIdString = parentCategoryId ? `${parentCategoryId}` : '';
  }

  const getCategoryId = () => {
    if (categoryId !== '0') {
      return categoryId;
    } else {
      return `${dominantCategory?.id}` || '0';
    }
  };

  const getParentCategoryId = () => {
    if (parentCategoryId !== '0') {
      return parentCategoryId;
    } else {
      return (categoryId === '0' && `${dominantCategory?.parentId}`) || categoryId || '0';
    }
  };

  const getGptBannersDataProps = {
    analyticsCode: bannerProperties.analyticsCode,
    categoryId: getCategoryId(),
    deviceId: fingerPrint,
    experiments: {
      skinTest,
    },
    extraBannerParameters,
    hostname,
    labsConfig,
    loggedInUser,
    pageKey,
    pageType: pageKey,
    parentCategoryId: getParentCategoryId(),
    platform: 'web',
    positions,
    rsiSegs,
    tenantContext,
    consent,
  };

  const bannerData = await getGptBannersServerData({ xsrfToken, getGptBannersDataProps });

  return {
    adblockerFile,
    mainScriptUrl,
    bannerData,
    enableSingleRequest: true,
    enableAnalytics: true,
    publisherProvidedId: loggedInUser.isLoggedIn ? loggedInUser.encryptedId : null,
    gptUrl: bannerProperties.gptUrl,
    malvertisingProtectionId: bannerProperties.malvertisingProtectionId,
    categoryId: catIdString,
    vurl,
    grafanaSuffix: '',
    gptBatchMetricsEndpointUrl: bannerProperties.gptBatchMetricsEndpointUrl,
    releaseId,
    experiments: {
      enableTmp,
      skinTest,
      useSourcePointCMP,
      isPubmaticWrapperEnabled,
    },
  };
};

export default createBannerDataConfig;
