import React, { ReactNode } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

interface ViewControlGroupProps {
  children: ReactNode;
  type?: string;
}

const ViewControlGroup: React.FC<ViewControlGroupProps> = ({ children, type = '' }) => (
  <div className={getSuitCSSClasses({ namespace: 'hz', name: 'ViewControl-group', modifiers: [type] })}>{children}</div>
);

export default ViewControlGroup;
