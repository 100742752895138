import React from 'react';
import { node } from 'prop-types';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

const ZrpSaveSearchHelper = ({ children }) => (
  <div className="hz-zrsp-saveSearchButton">
    <Trans className="hz-zrsp-message" i18nKey="saveSearch.messages.noResults" />
    <div className="hz-zrsp-button">{children}</div>
  </div>
);

ZrpSaveSearchHelper.propTypes = {
  children: node.isRequired,
};

export default ZrpSaveSearchHelper;
